import React from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import { PanelDataErrorView } from '@grafana/runtime';

interface Props extends PanelProps<SimpleOptions> {}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height, fieldConfig, id }) => {
  if (options.url){
    const from = data.timeRange.from.unix()
    const to = data.timeRange.from.unix()

    const mapUrl = `${options.url}&from=${from}&to=${to}`;

    return (
      <div style={{
        width: '100%',
        height: '100%'
      }}>
        <iframe
          src={mapUrl}
          style={{
            display: 'block',
            width: '100%',
            height: '100%',
            border: undefined,
          }}
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        >
      </iframe>
      </div>
    );
  } else {
    return <PanelDataErrorView fieldConfig={fieldConfig} panelId={id} data={data} message="ERROR: url not set" />;
  }
};
